const currencyCountries = {
  USD: [
    "AS",
    "VG",
    "EC",
    "SV",
    "GU",
    "TL",
    "MH",
    "FM",
    "PW",
    "MP",
    "PR",
    "TC",
    "US",
    "VI",
    "IO",
  ],
  EUR: [
    "NL",
    "AD",
    "BE",
    "ES",
    "GP",
    "IE",
    "IT",
    "AT",
    "GR",
    "HR",
    "CY",
    "LV",
    "LT",
    "LU",
    "MT",
    "MQ",
    "YT",
    "PT",
    "FR",
    "GF",
    "RE",
    "PM",
    "DE",
    "SM",
    "SK",
    "SI",
    "FI",
    "VA",
    "EE",
    "TF",
    "ME",
    "BL",
    "XK",
    "AX",
    "MF",
  ],
  GBP: ["GB", "GS"],
};

export default currencyCountries;
