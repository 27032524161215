<template>
  <v-row no-gutters class="d-flex flex-column">
    <div
      class="d-flex align-center"
      :class="{ 'justify-space-between': $vuetify.breakpoint.xs }"
    >
      <v-progress-circular
        v-if="exchangeRatesLoading"
        indeterminate
        color="red"
      />
      <div class="select-container">
        <v-select
          v-model="equivalent"
          hide-details
          class="rounded-lg"
          :class="{ 'select-mobile': $vuetify.breakpoint.xs }"
          :items="equivalents"
          solo
          append-icon="mdi-chevron-down"
          dense
          @change="changeCurrency"
        />
      </div>
    </div>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import currencyCountries from "@/plugins/currencyCountries.js";
export default {
  name: "CurrencyConverter",
  components: {
    ErrorComponent: () => import("@/components/Core/ErrorComponent"),
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
  watch: {
    amount() {
      this.changeCurrency();
    },
  },
  data() {
    return {
      equivalents: [
        { value: "EUR", text: "EUR (€)" },
        { value: "USD", text: "USD ($)" },
        { value: "GBP", text: "GBP (£)" },
        { value: "TTU", text: "TTU" },
      ],
      equivalentsIcon: ["€", "$", "£", "TTU"],
      equivalent: "EUR",
    };
  },
  computed: {
    ...mapGetters({
      exchangeRatesLoading: "wallet/exchangeRatesLoading",
      exchangeRates: "wallet/exchangeRates",
      exchangeRatesError: "wallet/exchangeRatesError",
      userLocation: "auth/location",
    }),
    currencyConversion() {
      if (this.exchangeRates == null && this.amount === 0) {
        return 0;
      } else {
        return this.equivalent === "TTU"
          ? this.amount
          : this.amount / this.exchangeRates[this.equivalent];
      }
    },
    icon() {
      return this.equivalentsIcon[
        this.equivalents.findIndex((e) => (e.value == this.equivalent))
      ];
    },
  },
  async mounted() {
    await this.getUserLocation()
    this.setInitialCurrency()
  },
  created() {
    this.$store.dispatch("wallet/getExchangeRates");
  },
  methods: {
    ...mapActions({
      getUserLocation: "auth/getUserLocation",
    }),
    changeCurrency() {
      this.$emit("currencyChanged", {
        amount: isNaN(this.amount) ? 0 : this.currencyConversion,
        icon: this.icon,
      });
    },
    setInitialCurrency() {
      if (currencyCountries['EUR'].includes(this.userLocation)) {
        this.equivalent = 'EUR'
      }
      else if (currencyCountries['USD'].includes(this.userLocation)) {
        this.equivalent = 'USD'
      }
      else if (currencyCountries['GBP'].includes(this.userLocation)) {
        this.equivalent = 'GBP'
      }
      else {
        this.equivalent = 'TTU'
      }
      this.changeCurrency()
    }    
  },
};
</script>
<style lang="scss">
.select-placeholder-currency .v-select__selection {
  color: var(--v-primary-base) !important;
  text-decoration: underline var(--v-primary-base) !important;
}
.select-container {
  width: 115px;
  .select-mobile {
    margin-top: -12px;
  }
}
</style>
